import gql from 'graphql-tag';

export const GetCaseSubmission = gql`
  query($id: ID!) {
    petrophysicsSubmission(id: $id) {
      id
      validationStatus
      processingStatus
      loadStartTime: startedAt
      loadEndTime: endedAt
      originalFileName
      blobContainer
      blobName
      blobSize
      mimeType
      _case: case {
        id
        name
      }
      field {
        id
        name
        code
      }
      project {
        id
        projectKey: key
        projectName: name
        projectAsOfDate: asOfDate
      }
      creator: user {
        oid
        name
      }
      details {
        id
        severity
        description
      }
    }
  }
`;

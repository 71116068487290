import * as actions from '../actions/actionTypes';
import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import throttle from 'lodash.throttle';

import { store } from '../App';

import { THROTTLE_UPLAD_PROGRESS_DELAY, ROUTE_TO_DASHBOARD } from './constants';

import { uploadFileProgress } from '../actions/caseSubmissionActions';

import { errorHandler } from './errorHandler';

/* file upload progress */
const dispatchFileProgressAction = (container, name, progress) => {
  store.dispatch(uploadFileProgress(container, name, progress));
};

export const throttledFileProgress = throttle(dispatchFileProgressAction, THROTTLE_UPLAD_PROGRESS_DELAY);

/**
 * ERROR HANDLING
 */
export function* errorHandlerSaga(err) {
  const [openSnackbar, messageSnackbar, redirect, redirectTo] = errorHandler(err);
  yield handleSnackbar(openSnackbar, messageSnackbar);
  yield handleRedirect(redirect, redirectTo);
}

export function* handleSnackbar(openSnackbar = false, messageSnackbar = '') {
  yield openSnackbar &&
    put({
      type: actions.OPEN_GLOBAL_SNACKBAR,
      payload: { message: messageSnackbar },
    });
}

export function* handleRedirect(redirect = false, redirectTo = ROUTE_TO_DASHBOARD) {
  yield redirect && put(push(redirectTo));
}

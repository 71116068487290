// keys
export const KEY_INVALID_AUTHENTICATION_TOKEN = 'INVALID_AUTHENTICATION_TOKEN';

// codes
export const CODE_UNAUTHORIZED = 401;
export const CODE_FORBIDDEN = 403;
export const CODE_SERVER_ERROR = 500;

// code detected errors
export const NO_FILE = 'You did not select a valid file';
export const INVALID_SAS_URL = 'INVALID_SAS_URL';

import { filterTableColumns } from './storeFunctions';
import tableColumns from '../components/caseSubmissionOverview/tableColumns';

/* GLOBAL STATES */
export const initialGlobalState = {
  isSnackbarOpen: false,
  snackbarMessage: '',
  fixedHeader: true,
};

/* AUTH STATES */
export const initialAuthState = {
  user: {},
  accessLevel: 0,
  token: null,
  isErr: false,
  err: null,
  isAuthenticated: false,
  requestedPage: null,
  isInitialRequestedPageStored: false,
};

export const defaultCaseSubmissionFormState = () => ({
  project: null,
  field: null,
  _case: null,
  file: null,
});

export const defaultFormErrorsState = () => ({});

const defaultColumnsToShow = [
  'view',
  'id',
  'projectKey',
  'projectName',
  'projectAsOfDate',
  'createdAt',
  'fieldName',
  'caseName',
  'validationStatus',
  'processingStatus',
  'userName',
];

export const initialCaseSubmissionState = {
  // submit in progress related
  submitInProgress: false,
  fileUploadProgress: 0,
  // dashboard table
  tableData: [],
  columns: filterTableColumns(tableColumns, defaultColumnsToShow),
  columnsToShow: defaultColumnsToShow,
  loadingTableData: false,
  caseSubmissionsPageCount: null,
  // project autocomplete
  projectAutocompleteOptions: [],
  isLoadingProjects: false,
  // field autocomplete
  fieldAutocompleteOptions: [],
  isLoadingFields: false,
  // case autocomplete
  caseAutocompleteOptions: [],
  isLoadingCases: false,
  // form
  loadingForm: true,
  formErrors: defaultFormErrorsState(),
  form: defaultCaseSubmissionFormState(),
};

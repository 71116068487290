/* router - navigation actions */
export const NAVIGATE_TO_DASHBOARD_SAGA = 'NAVIGATE_TO_DASHBOARD_SAGA';

/* user actions */
export const TOGGLE_FIXED_HEADER = 'TOGGLE_FIXED_HEADER';

/* auth actions*/
export const AZURE_TOKEN_SUCCESS = 'AZURE_TOKEN_SUCCESS';
export const AZURE_TOKEN_FAILURE = 'AZURE_TOKEN_FAILURE';

export const REQUESTED_PAGE_ON_OPEN = 'REQUESTED_PAGE_ON_OPEN';
export const CLEAR_REQUESTED_PAGE_ON_OPEN = 'CLEAR_REQUESTED_PAGE_ON_OPEN';

/* form actions */
export const CHANGE_CASESUBMISSION_FORM_DATA = 'CHANGE_CASESUBMISSION_FORM_DATA';

export const CHANGE_CASESUBMISSION_AUTOCOMPLETE_FIELD = 'CHANGE_CASESUBMISSION_AUTOCOMPLETE_FIELD';

export const SEARCH_FOR_PROJECTS_SAGA = 'SEARCH_FOR_PROJECTS_SAGA';
export const SEARCH_FOR_PROJECTS_SAGA_SUCCESS = 'SEARCH_FOR_PROJECTS_SAGA_SUCCESS';
export const SEARCH_FOR_PROJECTS_SAGA_FAILURE = 'SEARCH_FOR_PROJECTS_SAGA_FAILURE';

export const SEARCH_FOR_FIELDS_SAGA = 'SEARCH_FOR_FIELDS_SAGA';
export const SEARCH_FOR_FIELDS_SAGA_SUCCESS = 'SEARCH_FOR_FIELDS_SAGA_SUCCESS';
export const SEARCH_FOR_FIELDS_SAGA_FAILURE = 'SEARCH_FOR_FIELDS_SAGA_FAILURE';

export const SEARCH_FOR_CASES_SAGA = 'SEARCH_FOR_CASES_SAGA';
export const SEARCH_FOR_CASES_SAGA_SUCCESS = 'SEARCH_FOR_CASES_SAGA_SUCCESS';
export const SEARCH_FOR_CASES_SAGA_FAILURE = 'SEARCH_FOR_CASES_SAGA_FAILURE';

// form upload
export const START_UPLOAD_AND_SUBMIT_SAGA = 'START_UPLOAD_AND_SUBMIT_SAGA';
export const START_UPLOAD_AND_SUBMIT_SAGA_SUCCESS = 'START_UPLOAD_AND_SUBMIT_SAGA_SUCCESS';
export const START_UPLOAD_AND_SUBMIT_SAGA_FAILURE = 'START_UPLOAD_AND_SUBMIT_SAGA_FAILURE';

export const SET_FILE_FOR_UPLOAD = 'SET_FILE_FOR_UPLOAD';
export const RESET_FILE_FOR_UPLOAD = 'RESET_FILE_FOR_UPLOAD';

export const UPLOAD_FILE_PROGRESS = 'UPLOAD_FILE_PROGRESS';
export const UPLOAD_FILE_DONE = 'UPLOAD_FILE_DONE';

// file removal
export const REMOVE_FILE_FROM_PROJECT = 'REMOVE_FILE_FROM_PROJECT';

// general case submission actions
export const FETCH_CASESUBMISSION_SAGA = 'FETCH_CASESUBMISSION_SAGA';
export const FETCH_CASESUBMISSION_SAGA_SUCCESS = 'FETCH_CASESUBMISSION_SAGA_SUCCESS';
export const FETCH_CASESUBMISSION_SAGA_FAILURE = 'FETCH_CASESUBMISSION_SAGA_FAILURE';

export const FETCH_CASESUBMISSIONS_SAGA = 'FETCH_CASESUBMISSIONS_SAGA';
export const FETCH_CASESUBMISSIONS_SAGA_SUCCESS = 'FETCH_CASESUBMISSIONS_SAGA_SUCCESS';
export const FETCH_CASESUBMISSIONS_SAGA_FAILURE = 'FETCH_CASESUBMISSIONS_SAGA_FAILURE';

export const RESET_LOADING_FORM_STATUS = 'RESET_LOADING_FORM_STATUS';
export const LOAD_NEW_CASESUBMISSION_FORM = 'LOAD_NEW_CASESUBMISSION_FORM';

// export const CHANGE_DISPLAYED_COLUMNS = "CHANGE_DISPLAYED_COLUMNS";

/* global */
export const CLOSE_GLOBAL_SNACKBAR = 'CLOSE_GLOBAL_SNACKBAR';
export const OPEN_GLOBAL_SNACKBAR = 'OPEN_GLOBAL_SNACKBAR';

export const GRAPHQL_ERROR_SAGA = 'GRAPHQL_ERROR_SAGA';

import gql from 'graphql-tag';

export const GetCaseSubmissions = gql`
  query($take: Int, $skip: Int, $filter: PetrophysicsSubmissionFilter, $sort: [PetrophysicsSubmissionSort!]) {
    petrophysicsSubmissionCount(filter: $filter)
    petrophysicsSubmissions(take: $take, skip: $skip, filter: $filter, sort: $sort) {
      id
      createdAt
      validationStatus
      processingStatus
      loadStartTime: startedAt
      loadEndTime: endedAt
      case {
        id
        name
      }
      field {
        id
        name
      }
      project {
        id
        projectKey: key
        projectName: name
        projectAsOfDate: asOfDate
      }
      creator: user {
        oid
        name
      }
    }
  }
`;

import { CODE_UNAUTHORIZED, CODE_SERVER_ERROR, CODE_FORBIDDEN, NO_FILE, INVALID_SAS_URL } from './errorConstants';
import { ROUTE_TO_LOGIN, MSAL_IDTOKEN_KEY } from './constants';

// handle saga errors
// determine whether to display snackbar and/or redirect
export const errorHandler = err => {
  const graphQLErrors = err?.graphQLErrors;
  const networkError = err?.networkError;

  let openSnackbar = false;
  let redirect = false;
  let messageSnackbar = '';
  let redirectTo = '';

  if (graphQLErrors) {
    graphQLErrors.forEach(element => {
      switch (element.code) {
        case CODE_UNAUTHORIZED:
          switch (element.message) {
            default:
              /* always redirect to login when unauthorized
               * or forbidden (token expired)
               */
              redirect = true;
              redirectTo = ROUTE_TO_LOGIN;
          }
          break;
        case CODE_FORBIDDEN:
          /* forbidden means token invalid or expired, so remove it
           * and redirect user to login
           */
          localStorage.removeItem(MSAL_IDTOKEN_KEY);

          redirect = true;
          redirectTo = ROUTE_TO_LOGIN;
          break;
        case CODE_SERVER_ERROR:
          openSnackbar = true;
          messageSnackbar = 'Something went wrong';
          break;
        default:
      }
    });
  }

  // handle network errors
  if (networkError) {
    openSnackbar = true;
    messageSnackbar = 'Something went wrong';
  }

  if (!graphQLErrors && !networkError && typeof err === 'string') {
    // we threw error explicitly in our code
    switch (err) {
      case NO_FILE:
        openSnackbar = true;
        messageSnackbar = 'No file was selected';
        break;
      case INVALID_SAS_URL:
        openSnackbar = true;
        messageSnackbar = 'Something went wrong. Try again later or contact your administrator';
        break;
      default:
    }
  }

  return [openSnackbar, messageSnackbar, redirect, redirectTo];
};

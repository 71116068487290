import * as actions from '../actions/actionTypes';
import { takeEvery } from 'redux-saga/effects';

import {
  fetchProjectsSaga,
  fetchCaseSubmissionSaga,
  fetchCaseSubmissionsSaga,
  uploadFileAndSubmitFormSaga,
  fetchFieldsSaga,
  fetchCasesSaga,
} from './caseSubmissionSagas';

export function* watchAll() {
  yield takeEvery(actions.SEARCH_FOR_PROJECTS_SAGA, fetchProjectsSaga);
  yield takeEvery(actions.SEARCH_FOR_FIELDS_SAGA, fetchFieldsSaga);
  yield takeEvery(actions.SEARCH_FOR_CASES_SAGA, fetchCasesSaga);
  yield takeEvery(actions.FETCH_CASESUBMISSION_SAGA, fetchCaseSubmissionSaga);
  yield takeEvery(actions.FETCH_CASESUBMISSIONS_SAGA, fetchCaseSubmissionsSaga);
  yield takeEvery(actions.START_UPLOAD_AND_SUBMIT_SAGA, uploadFileAndSubmitFormSaga);
}

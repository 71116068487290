import * as actions from '../actions/actionTypes';
import { put, call, select } from 'redux-saga/effects';

import {
  BlockBlobURL,
  uploadBrowserDataToBlockBlob,
  AnonymousCredential,
  StorageURL,
  Aborter,
} from '@azure/storage-blob';

import client from '../config/apolloConfig';

import { GetCases } from '../graphql/queries/GetCases';
import { GetFields } from '../graphql/queries/GetFields';
import { GetProjects } from '../graphql/queries/GetProjects';
import { GetCaseSubmission } from '../graphql/queries/GetCaseSubmission';
import { GetCaseSubmissions } from '../graphql/queries/GetCaseSubmissions';
import { GenerateAzureBlobUrl } from '../graphql/queries/GenerateAzureBlobUrl';

import { CreateCaseSubmissionMutation } from '../graphql/mutations/CreateCaseSubmission';

import {
  DEFAULT_AUTOCOMPLETE_FETCH_LIMIT,
  ROUTE_TO_DASHBOARD_ERROR_PAGE,
  UNABLE_TO_FIND_CASESUBMISSION,
  DEFAULT_PAGES_COUNT,
  ROUTE_TO_DASHBOARD,
} from '../utils/constants';
import { NO_FILE, INVALID_SAS_URL } from '../utils/errorConstants';

import { getFileExtension, getColumnSortId } from '../utils/functions';
import { throttledFileProgress, errorHandlerSaga, handleSnackbar, handleRedirect } from '../utils/sagaHelperFunctions';

export function* fetchProjectsSaga(action) {
  try {
    const result = yield call(() =>
      client.query({
        query: GetProjects,
        fetchPolicy: 'no-cache',
        variables: {
          filter: {
            ...(action.payload.value && {
              keyOrNameContains: action.payload.value /* show projects that are not deleted for given search string */,
            }),
          },
          take: DEFAULT_AUTOCOMPLETE_FETCH_LIMIT,
        },
      })
    );

    const data = result?.data?.projects || null;

    yield put({
      type: actions.SEARCH_FOR_PROJECTS_SAGA_SUCCESS,
      payload: { data },
    });
  } catch (err) {
    yield errorHandlerSaga(err);

    yield put({ type: actions.SEARCH_FOR_PROJECTS_SAGA_FAILURE, paload: null });
  }
}

export function* fetchFieldsSaga(action) {
  try {
    const result = yield call(() =>
      client.query({
        query: GetFields,
        fetchPolicy: 'no-cache',
        variables: {
          ...(action.payload.value && {
            filter: { codeOrNameContains: action.payload.value },
          }),
          take: DEFAULT_AUTOCOMPLETE_FETCH_LIMIT,
        },
      })
    );

    const data = result?.data?.fields || null;

    yield put({
      type: actions.SEARCH_FOR_FIELDS_SAGA_SUCCESS,
      payload: { data },
    });
  } catch (err) {
    yield errorHandlerSaga(err);

    yield put({ type: actions.SEARCH_FOR_FIELDS_SAGA_FAILURE, paload: null });
  }
}

export function* fetchCasesSaga(action) {
  try {
    const result = yield call(() =>
      client.query({
        query: GetCases,
        fetchPolicy: 'no-cache',
        variables: {
          ...(action.payload.value && {
            filter: { nameContains: action.payload.value },
          }),
          take: DEFAULT_AUTOCOMPLETE_FETCH_LIMIT,
        },
      })
    );

    const data = result?.data?.petrophysicsCases || null;

    yield put({
      type: actions.SEARCH_FOR_CASES_SAGA_SUCCESS,
      payload: { data },
    });
  } catch (err) {
    yield errorHandlerSaga(err);

    yield put({ type: actions.SEARCH_FOR_CASES_SAGA_FAILURE, paload: null });
  }
}

export function* fetchCaseSubmissionSaga(action) {
  try {
    const result = yield call(() =>
      client.query({
        query: GetCaseSubmission,
        variables: { id: action.payload.id },
      })
    );

    const data = result?.data?.petrophysicsSubmission || null;

    if (!data) {
      throw UNABLE_TO_FIND_CASESUBMISSION;
    }

    yield put({
      type: actions.FETCH_CASESUBMISSION_SAGA_SUCCESS,
      payload: { data },
    });
  } catch (err) {
    yield handleRedirect(true, ROUTE_TO_DASHBOARD_ERROR_PAGE);

    yield put({
      type: actions.FETCH_CASESUBMISSION_SAGA_FAILURE,
      payload: null,
    });
  }
}

export function* fetchCaseSubmissionsSaga(action) {
  try {
    const sorted = action?.payload?.sorted[0];

    let sort = [];
    if (sorted) {
      const column = getColumnSortId(sorted.id);
      if (column) {
        sort.push({ column, order: !!sorted.desc ? 'DESC' : 'ASC' });
      }
    } else {
      sort.push({ column: getColumnSortId('id'), order: 'DESC' });
    }

    let where = null;
    for (const filter of action?.payload?.filtered) {
      switch (filter.id) {
        case 'createdAt':
        case 'validationStatus':
        case 'processingStatus':
        case 'projectAsOfDate':
          if (filter.value) {
            where = { ...where, [filter.id]: filter.value };
          }
          break;
        default:
          where = {
            ...where,
            [filter.id + 'Contains']: filter.value.toLowerCase(),
          };
      }
    }

    let variables = null;
    variables = {
      skip: action.payload.page * action.payload.pageSize,
      take: action.payload.pageSize,
      ...(where && { filter: where }),
      sort,
    };

    const result = yield call(() =>
      client.query({
        query: GetCaseSubmissions,
        variables,
        fetchPolicy: 'no-cache',
      })
    );

    const caseSubmissions = result?.data?.petrophysicsSubmissions || [];

    const count = result?.data?.petrophysicsSubmissionCount;

    const caseSubmissionsPageCount = count ? Math.ceil(count / action.payload.pageSize) : DEFAULT_PAGES_COUNT;

    yield put({
      type: actions.FETCH_CASESUBMISSIONS_SAGA_SUCCESS,
      payload: { data: caseSubmissions, caseSubmissionsPageCount },
    });
  } catch (err) {
    yield errorHandlerSaga(err);

    yield put({
      type: actions.FETCH_CASESUBMISSIONS_SAGA_FAILURE,
      payload: null,
    });
  }
}

/*
 *
 * SUBMIT SAGA
 *
 */
export function* uploadFileAndSubmitFormSaga(action) {
  try {
    /**
     *
     * START FILE UPLOAD PROCESS
     *
     */

    let caseSubmissionForm = {
      ...(yield select(state => state.caseSubmission.form)),
    };

    const file = caseSubmissionForm?.file || null;

    if (!file) {
      throw NO_FILE;
    }

    // generate url for a new file
    const generateSASUrlResult = yield call(() =>
      client.query({
        query: GenerateAzureBlobUrl,
        variables: {
          projectId: caseSubmissionForm.project.id,
          fieldId: caseSubmissionForm.field.id,
          extension: getFileExtension(file.name),
        },
        fetchPolicy: 'no-cache',
      })
    );

    const sasURL = generateSASUrlResult?.data?.petrophysicsGenerateUploadBlobSASUrl || null;

    if (!sasURL) {
      throw INVALID_SAS_URL;
    }

    // extract container and blob name from url
    const urlParts = /(https:\/\/.*?)\/(.*?)\/(.*?)\?(.*)/.exec(sasURL);
    const blobContainer = urlParts[2];
    const blobName = urlParts[3];

    // https://github.com/Azure/azure-storage-node/issues/534
    const pipeline = StorageURL.newPipeline(new AnonymousCredential());

    const blockBlobURL = new BlockBlobURL(sasURL, pipeline);

    // upload file
    yield uploadBrowserDataToBlockBlob(Aborter.none, file.fileObject, blockBlobURL, {
      // eslint-disable-next-line no-loop-func
      progress: ev => {
        const progress = ev.loadedBytes / file.size;

        throttledFileProgress(blobContainer, blobName, progress);
      },
    });

    /* we cannot rely that uploads' progress callback will
     * correctly send last expected action
     * so send one manually
     */
    yield put({
      type: actions.UPLOAD_FILE_DONE,
      payload: null,
    });

    yield throttledFileProgress.flush();

    /**
     *
     * START CASE SUBMISSION... SUBMISSION
     *
     */
    yield call(() =>
      client.mutate({
        mutation: CreateCaseSubmissionMutation,
        variables: {
          projectId: caseSubmissionForm.project.id,
          fieldId: caseSubmissionForm?.field?.id,
          caseId: caseSubmissionForm?._case?.id,
          originalFileName: file.name,
          blobContainer,
          blobName,
          blobSize: file.size,
          mimeType: file.type,
        },
      })
    );

    // dispatch action on all uploads finished
    yield put({
      type: actions.START_UPLOAD_AND_SUBMIT_SAGA_SUCCESS,
      payload: null,
    });

    yield handleSnackbar(true, 'Case submission success!');
    yield handleRedirect(true, ROUTE_TO_DASHBOARD);
  } catch (err) {
    yield errorHandlerSaga(err);

    yield put({
      type: actions.START_UPLOAD_AND_SUBMIT_SAGA_FAILURE,
      payload: null,
    });
  }
}

/*
 * filters tableColumns (where all possible columns are)
 * to only show the ones we want to display
 */
export const filterTableColumns = (tableColumns, columnsToShow) => {
  let columns = tableColumns.filter(row => {
    if (row.columns) {
      return true;
    } else {
      if (row.id && columnsToShow.includes(row.id)) {
        return true;
      } else {
        return false;
      }
    }
  });

  columns = columns.map(row => {
    if (row.columns) {
      // it is just a header group, go into columns below
      return {
        ...row,
        columns: row.columns.filter(subRow => {
          if (subRow.id && columnsToShow.includes(subRow.id)) {
            return true;
          } else {
            return false;
          }
        }),
      };
    }
    return row;
  });
  return columns;
};

export const caseSubmissionValidation = (currentFormErrors, field, value) => {
  // validation
  let formErrors = { ...currentFormErrors };
  switch (field) {
    case '_case':
      // check if valid value
      if (!value || value === null || !value.id || !value.name) {
        formErrors[field] = 'Invalid or missing case';
        break;
      }

      formErrors[field] = null;
      break;
    case 'field':
      // check if valid value
      if (!value || value === null || !value.name) {
        formErrors[field] = 'Invalid or missing field';
        break;
      }

      formErrors[field] = null;
      break;
    case 'project':
      // check if valid value
      if (!value || value === null || !value.id || !value.projectKey || !value.projectName) {
        formErrors[field] = 'Invalid or missing project';
        break;
      }

      formErrors[field] = null;
      break;
    default:
  }

  return formErrors;
};

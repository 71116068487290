import React from 'react';

import IconButton from '@material-ui/core/IconButton';
// import Tooltip from "@material-ui/core/Tooltip";

import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
// import CircleIcon from "@material-ui/icons/FiberManualRecord";

import { formatDateUS, formatDateTimeUS, determineStatusHexCode, determineStatusText } from '../../utils/functions';
import LinkComponent from '../../utils/LinkComponent';

import CustomDatePicker from '../../common/Pickers';

// set this on all columns,
// due to bug: https://github.com/tannerlinsley/react-table/issues/1272
// minResizeWidth: 10
const columns = [
  {
    show: true,
    filterable: false,
    sortable: false,
    id: 'edit',
    Header: 'Edit',
    minResizeWidth: 10,
    width: 60,
    Cell: row => {
      return (
        <LinkComponent to={`/submission/${row.original.id}`}>
          <IconButton>
            <EditIcon />
          </IconButton>
        </LinkComponent>
      );
    },
  },
  {
    show: true,
    filterable: false,
    sortable: false,
    id: 'view',
    Header: 'View',
    minResizeWidth: 10,
    width: 60,
    Cell: row => {
      return (
        <LinkComponent to={`/submission/${row.original.id}`}>
          <IconButton>
            <VisibilityIcon />
          </IconButton>
        </LinkComponent>
      );
    },
  },
  {
    show: true,
    id: 'id',
    Header: 'ID',
    accessor: 'id',
    minResizeWidth: 10,
    width: 50,
  },
  {
    Header: 'Project',
    columns: [
      {
        show: true,
        filterable: true,
        id: 'projectKey',
        Header: 'Project ID',
        accessor: 'project.projectKey',
        minResizeWidth: 10,
      },
      {
        show: true,
        filterable: true,
        id: 'projectName',
        Header: 'Project Name',
        accessor: 'project.projectName',
        minResizeWidth: 10,
      },
      {
        show: true,
        filterable: true,
        sortable: true,
        id: 'projectAsOfDate',
        Header: 'Project as of date',
        accessor: row => {
          return formatDateUS(row.project.projectAsOfDate);
        },
        Filter: ({ filter, onChange }) => {
          return (
            <CustomDatePicker
              USFormat
              disableUnderline
              fullWidth
              clearable
              placeholder="Filter by date"
              defaultValue={filter ? filter.value : null}
              onChange={selectedDate => {
                onChange(selectedDate);
              }}
            />
          );
        },
        minWidth: 140,
        minResizeWidth: 10,
      },
    ],
  },
  {
    Header: '',
    columns: [
      {
        show: true,
        filterable: true,
        id: 'createdAt',
        Header: 'Create date',
        accessor: 'createdAt',
        Cell: row => {
          return <div title={formatDateTimeUS(row.value)}>{formatDateUS(row.value)}</div>;
        },
        Filter: ({ filter, onChange }) => {
          return (
            <CustomDatePicker
              USFormat
              disableUnderline
              fullWidth
              clearable
              placeholder="Filter by date"
              defaultValue={filter ? filter.value : null}
              onChange={selectedDate => {
                onChange(selectedDate);
              }}
            />
          );
        },
        minWidth: 120,
        minResizeWidth: 10,
      },
    ],
  },
  {
    Header: 'Submission',
    columns: [
      {
        show: true,
        filterable: true,
        sortable: true,
        id: 'fieldName',
        Header: 'Field Name',
        accessor: 'field.name',
        minResizeWidth: 10,
      },
      {
        show: true,
        filterable: true,
        sortable: true,
        id: 'caseName',
        Header: 'Case Name',
        accessor: 'case.name',
        minResizeWidth: 10,
      },
      {
        show: true,
        filterable: true,
        sortable: true,
        id: 'validationStatus',
        Header: 'Validation Status',
        accessor: 'validationStatus',
        Cell: row => {
          return <div style={{ color: determineStatusHexCode(row.value) }}>{determineStatusText(row.value)}</div>;
        },
        Filter: ({ onChange }) => {
          return (
            <select className="ReactTableNativeSelect" onChange={({ target: { value } }) => onChange(value)}>
              <option value="">View all</option>
              <option value="IN_QUEUE">Queued</option>
              <option value="IN_PROGRESS">In progress</option>
              <option value="SUCCESS">Success</option>
              <option value="FAILURE">Failed</option>
              <option value="WARNING">Warning</option>
            </select>
          );
        },
        minResizeWidth: 10,
      },
      {
        show: true,
        filterable: true,
        sortable: true,
        id: 'processingStatus',
        Header: 'Processing Status',
        accessor: 'processingStatus',
        Cell: row => {
          return (
            <div style={{ color: determineStatusHexCode(row.value) }}>{determineStatusText(row.value)}</div>
            //     <div className="ReactTableCellTextAlignCenter">
            //       <Tooltip title={row.value} placement="right">
            //         <CircleIcon
            //           style={{ color: determineStatusHexCode(row.value) }}
            //         />
            //       </Tooltip>
            //     </div>
          );
        },
        Filter: ({ onChange }) => {
          return (
            <select className="ReactTableNativeSelect" onChange={({ target: { value } }) => onChange(value)}>
              <option value="">View all</option>
              <option value="IN_QUEUE">Queued</option>
              <option value="IN_PROGRESS">In progress</option>
              <option value="SUCCESS">Success</option>
              <option value="FAILURE">Failed</option>
            </select>
          );
        },
        minResizeWidth: 10,
      },
    ],
  },
  {
    Header: 'Creator',
    columns: [
      // {
      //   show: true,
      //   id: "creatorOid",
      //   Header: "oid",
      //   accessor: "creator.oid",
      //   minResizeWidth: 10
      // },
      {
        show: true,
        sortable: true,
        filterable: true,
        id: 'userName',
        Header: 'Name',
        accessor: 'creator.name',
        minResizeWidth: 10,
      },
      // {
      //   show: true,
      //   id: "creatorPhone",
      //   Header: "phone",
      //   accessor: "creator.phone",
      //   minResizeWidth: 10
      // }
    ],
  },
];

export default columns;

import gql from 'graphql-tag';

export const GetFields = gql`
  query($take: Int, $skip: Int, $filter: FieldFilter, $sort: [FieldSort!]) {
    fields(take: $take, skip: $skip, filter: $filter, sort: $sort) {
      id
      name
      code
    }
  }
`;

import gql from 'graphql-tag';

export const GetProjects = gql`
  query($take: Int, $skip: Int, $filter: ProjectFilter, $sort: [ProjectSort!]) {
    projects(take: $take, skip: $skip, filter: $filter, sort: $sort) {
      id
      projectKey: key
      projectName: name
    }
  }
`;
